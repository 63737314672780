import './index.scss';

import React, { FC, useRef } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import CloseButton from '../CloseButton/CloseButton';
import Form from '../Form/Form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Socials from '../Socials/Socials';
import { useForm } from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';

interface IFeedbackModal {
	handleModal: () => void;
	modalState: boolean;
}

const FeedbackModal: FC<IFeedbackModal> = ({ handleModal, modalState }) => {
	const captchaRef = useRef(null);

	const {
		formData,
		captchaState,
		error,
		onFormSubmit,
		onInputChange,
		confirmation,
		showConfirmation,
		onConfirmationClose,
	} = useForm(captchaRef);

	const { t } = useTranslation();

	const handleConfirmation = () => {
		onConfirmationClose();
		handleModal();
	};

	return (
		<Backdrop
			modalState={modalState}
			handleModal={confirmation ? handleConfirmation : handleModal}
		>
			{confirmation ? (
				<div
					onClick={(
						e: React.MouseEvent<HTMLDivElement, MouseEvent>,
					) => e.stopPropagation()}
					className={`confirmation confirmation__${
						confirmation ? 'active' : 'disabled'
					}`}
				>
					<CloseButton handleClose={handleConfirmation} />
					<span className='confirmation__header'>
						{t('thanks.thanks')}
					</span>
					<span className='confirmation__text'>
						{t('thanks.text')}
					</span>
				</div>
			) : (
				<div
					className={`feedback feedback__${
						!confirmation ? 'active' : 'disabled'
					}`}
					onClick={(
						e: React.MouseEvent<HTMLDivElement, MouseEvent>,
					) => e.stopPropagation()}
				>
					<CloseButton handleClose={handleModal} />
					<h2 className='feedback__header'>{t('thanks.feedback')}</h2>
					<div className='feedback__form'>
						<Form
							formData={formData}
							error={error}
							onFormSubmit={onFormSubmit}
							onInputChange={onInputChange}
						/>
						<div className='feedback__form--wrapper'>
							<button
								className={`feedback__button ${captchaState}`}
								type='submit'
								onClick={onFormSubmit}
							>
								<span> {t('buttons.submit')}</span>
							</button>
							{captchaState === 'active' ? (
								<div className='feedback__captcha'>
									<ReCAPTCHA
										ref={captchaRef}
										sitekey={`${process.env.REACT_APP_KEY}`}
										onChange={showConfirmation}
									/>
								</div>
							) : null}
							<p className='feedback__agreement'>
								{t('footer.consentBlack')}
								{/* <Link to='privacy_policy'>
									{t('footer.consentGreen')}
								</Link> */}
								<Link to='/'>{t('footer.consentGreen')}</Link>
							</p>
						</div>
					</div>
					<Socials iconSize={40} listSize={168} />
				</div>
			)}
		</Backdrop>
	);
};

export default FeedbackModal;
