/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';

interface IUseModal {
	toggleModal: (modalName: string) => void;
	isModalOpen: (modalName: string) => boolean;
}

interface IModalState {
	[key: string]: boolean;
}

export const useModal = (): IUseModal => {
	const [modals, setModals] = useState<IModalState>({});

	const hasTrueValue = Object.entries(modals).some(
		([key, value]) => key !== 'dropdown' && value === true,
	);

	useEffect(() => {
		hasTrueValue
			? (document.body.style.overflow = 'hidden')
			: (document.body.style.overflow = 'scroll');
	}, [modals]);

	const toggleModal = (modalName: string) => {
		setModals((prevModals) => ({
			...prevModals,
			[modalName]: !prevModals[modalName],
		}));
	};

	const isModalOpen = (modalName: string) => modals[modalName] || false;

	return { toggleModal, isModalOpen };
};
