/* eslint-disable react-hooks/exhaustive-deps */

import './index.scss';

import React, { FC, useEffect, useRef } from 'react';

import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

interface IDropdown {
	toggleModal: (modalName: string) => void;
	isModalOpen: (modalName: string) => boolean;
	handleLanguageChange: (value: string) => void;
	currentLanguage: string;
}

const Dropdown: FC<IDropdown> = ({
	toggleModal,
	isModalOpen,
	handleLanguageChange,
	currentLanguage,
}) => {
	const { t } = useTranslation();

	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				isModalOpen('dropdown') &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				toggleModal('dropdown');
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [toggleModal]);

	const handleDropdown = () => {
		toggleModal('dropdown');
	};

	return (
		<div className='dropdown' ref={dropdownRef} onClick={handleDropdown}>
			<span>{t(`menu.${currentLanguage}`)}</span>
			{isModalOpen('dropdown') ? (
				<LanguageSwitcher
					handleLanguageChange={handleLanguageChange}
					currentLanguage={currentLanguage}
				/>
			) : null}
		</div>
	);
};

export default Dropdown;
