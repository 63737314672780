import './index.scss';

import React, { FC } from 'react';

import { IFormData } from '../../types/types';
import { useTranslation } from 'react-i18next';

interface InputProps {
	type: string;
	id: string;
	label: string;
	handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
	formData: IFormData;
	error: string;
	minLength?: number;
	maxLength?: number;
	required?: boolean;
}

const Input: FC<InputProps> = ({
	type,
	id,
	minLength,
	maxLength,
	required,
	label,
	handleInput,
	formData,
	error,
}) => {
	const { t } = useTranslation();

	const hasError = () =>
		error && error.toLowerCase().includes(id.toLowerCase());

	return (
		<div className='input'>
			<label>{label}</label>
			<input
				className={`${hasError() ? 'invalid' : 'valid'}`}
				onChange={(e) => handleInput(e)}
				type={type}
				id={id}
				name={id}
				value={formData[id]}
				minLength={minLength}
				maxLength={maxLength}
				required={required}
			/>
			{hasError() ? (
				<span className='input__error'>{t(`errors.${error}`)}</span>
			) : null}
		</div>
	);
};

export default Input;
