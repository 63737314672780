import './index.scss';

import React, { FC } from 'react';

import { Link as ScrollLink } from 'react-scroll';
import arrow_down from '../../assets/icons/arrow_down.svg';
import { useTranslation } from 'react-i18next';

const Hero: FC = () => {
	const { t } = useTranslation();

	return (
		<section className='hero'>
			<div className='hero__banner' />
			<div className='hero__wrapper'>
				<h2 className='hero__text'>
					<span className='hero__text--green'>
						{t('hero.textGreen')}
					</span>
					{t('hero.textBlack')}{' '}
					<span className='hero__text--name'>{t('hero.name')}</span>
				</h2>
				<ScrollLink
					to={'services'}
					smooth={true}
					delay={100}
					duration={1000}
				>
					<img src={arrow_down} alt='arrow_down' />
				</ScrollLink>
			</div>
		</section>
	);
};

export default Hero;
