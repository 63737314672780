import Backend from 'i18next-http-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		lng: localStorage.getItem('lng') || 'en',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
		resources: {
			en: {
				translation: require('./en/en.json'),
			},
			uk: {
				translation: require('./uk/uk.json'),
			},
		},
	});

export default i18n;
