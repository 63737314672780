import './index.scss';

import React, { FC } from 'react';

import Dropdown from '../../components/Dropdown/Dropdown';
import { Link } from 'react-router-dom';
import MobileMenu from '../../components/MobileMenu/MobileMenu';
import MobileMenuList from '../../components/MobileMenuList/MobileMenuList';
import i18n from '../../locales/i18n';
import logo from '../../assets/icons/logo.svg';
import menu_open from '../../assets/icons/icon_menu.svg';
import { useTranslation } from 'react-i18next';

interface IHeader {
	handleModal: () => void;
	toggleModal: (modalName: string) => void;
	isModalOpen: (modalName: string) => boolean;
}

const Header: FC<IHeader> = ({ handleModal, toggleModal, isModalOpen }) => {
	const { t } = useTranslation();

	const handleLanguageChange = (value: string) => {
		i18n.changeLanguage(value);
		localStorage.setItem('lng', value);
	};

	return (
		<header className='header'>
			<Link to='/'>
				<img className='header__logo' src={logo} alt='SeLeV logo' />
			</Link>
			<div className='header__menu--wrapper'>
				<MobileMenuList />
				<Dropdown
					toggleModal={toggleModal}
					isModalOpen={isModalOpen}
					handleLanguageChange={handleLanguageChange}
					currentLanguage={i18n.language}
				/>
				<button className='header__button' onClick={handleModal}>
					<span>{t('menu.contactUs')}</span>
				</button>
				<img
					className='header__menu'
					src={menu_open}
					alt='menu'
					onClick={() => toggleModal('menu')}
				/>
			</div>
			{isModalOpen('menu') ? (
				<MobileMenu
					toggleModal={toggleModal}
					handleFeedback={handleModal}
					currentLanguage={i18n.language}
					handleLanguageChange={handleLanguageChange}
				/>
			) : null}
		</header>
	);
};

export default Header;
