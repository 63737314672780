import { ILanguage } from '../types/types';

export const languages: ILanguage[] = [
	{
		name: 'укр',
		value: 'uk',
	},
	{
		name: 'eng',
		value: 'en',
	},
];
