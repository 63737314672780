import './index.scss';

import React, { FC } from 'react';

import Consulting from '../../components/Consulting/Consulting';
import Divider from '../../components/Divider/Divider';
import Expertise from '../../components/Expertise/Expertise';
import { useTranslation } from 'react-i18next';

interface IServices {
	toggleModal: (modalName: string) => void;
	isModalOpen: (modalName: string) => boolean;
}

const Services: FC<IServices> = ({ toggleModal, isModalOpen }) => {
	const { t } = useTranslation();

	return (
		<section className='services' id='services'>
			<h2 className='services__header'>
				{t('services.headerBlack')}
				<span> {t('services.headerGreen')}</span>
			</h2>
			<div className='services__wrapper'>
				<Expertise
					isModalOpen={isModalOpen}
					toggleModal={toggleModal}
				/>
				<Divider />
				<Consulting
					isModalOpen={isModalOpen}
					toggleModal={toggleModal}
				/>
			</div>
		</section>
	);
};

export default Services;
