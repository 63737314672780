import './index.scss';

import React, { FC, useEffect } from 'react';

const PrivacyPolicy: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className='policy'>
			<h2 className='policy__header'>
				<span>Privacy</span> Policy
			</h2>
			<p className='policy__text'>
				This Privacy Policy explains how we collect, use, and protect
				your personal information. By using our website, you consent to
				the terms of this Privacy Policy. This Privacy Policy is
				effective as of the date of publication and supersedes all
				previous versions.
				<br />
				<br />
				FreedomLab is a website created by the{' '}
				<a
					href='https://www.osce.org/odihr'
					target='_blank'
					rel='noreferrer'
				>
					OSCE Office for Democratic Institutions and Human Rights
				</a>
				. To contact us, please{' '}
				<a
					href='https://freedomlab.io/contact-us/'
					target='_blank'
					rel='noreferrer'
				>
					visit this page
				</a>
				. Our website is primarily hosted through three auxiliary
				services: Pantheon, which hosts the domain, WordPress, which
				hosts the website, and LearnDash, which hosts the course
				material. Each of these services have their own privacy
				policies, which can be accessed on their websites:{' '}
				<a
					href='https://pantheon.pactsafe.io/legal.html#contract-r1gwog2ui'
					target='_blank'
					rel='noreferrer'
				>
					Pantheon
				</a>
				,{' '}
				<a
					href='https://wordpress.org/about/privacy/'
					target='_blank'
					rel='noreferrer'
				>
					WordPress
				</a>
				, and{' '}
				<a
					href='https://www.learndash.com/privacy-policy/'
					target='_blank'
					rel='noreferrer'
				>
					LearnDash
				</a>
				.
			</p>
			<h3 className='policy__subheader'>
				What type of information do we collect?
			</h3>
			<p className='policy__text'>
				The type and amount of information we receive and store depends
				on how you use FreedomLab. You can access most of the pages on
				FreedomLab without telling us who you are and without needing to
				provide any personal information. Our web servers collect
				anonymous logs during user visits to the website which provide
				valuable information for future improvement.
				<br />
				<br />
				Aside from your responses to questions, additional information
				collected via form submission is the date and time of
				submission.
				<br />
				<br />
				When you visit FreedomLab or engage with any course material,
				this information will be stored in your browser history. If you
				don’t want this to happen, we suggest that you use an incognito
				browser.
				<br />
				<br />
				If you register an account on FreedomLab, we will collect your
				email and name. We collect this data in registration for two
				purposes: 1.) so you can save your course progress; and 2.) so
				we can issue certificates with your name upon completion of the
				course. We will not use your name or email address for any other
				purpose, and you are still able to complete the courses without
				registering an account – however, you will not be able to get a
				certificate without registering.
			</p>
			<div className='policy__divider' />
			<h3 className='policy__subheader'>
				How is your data used and stored?
			</h3>
			<p className='policy__text'>
				Your personal information will be retained by ODIHR via
				WordPress for 4 months, and then be deleted. It will be kept
				confidential, and will only be used in connection with the
				purposes for which it is submitted.
			</p>
			<div className='policy__text--block'>
				<h3 className='policy__subheader'>External Data Usage</h3>
				<p className='policy__text'>
					External Data Usage Feedback forms are hosted through
					“NinjaForms” – their privacy policy can be found{' '}
					<a
						href='https://ninjaforms.com/privacy-policy/'
						target='_blank'
						rel='noreferrer'
					>
						on their website
					</a>
					.
					<br />
					<br />
					As videos in training courses are hosted on YouTube, videos
					watched may be stored in your YouTube history if you are
					logged in to your Google account whilst watching the videos.
					External hyperlinks in training courses will take you to
					uploaded pdfs or external webpages – whilst efforts have
					been made to ensure that the webpages attached to the course
					are legitimate, the security of your data on external
					webpages is your responsibility.
					<br />
					<br />
					The{' '}
					<a
						href='https://www.freedomlab.io/tools-for-hrds/'
						target='_blank'
						rel='noreferrer'
					>
						tool repository
					</a>{' '}
					will redirect you via hyperlinks to external webpages or
					online tools. ODIHR bears no responsibility to what happens
					to your data when you leave the FreedomLab site; therefore
					this privacy policy does not apply to pages hosted, or
					applications/tools operated by other organisations.
					<br />
					<br />
					FreedomLab visitors’ IP addresses and each request they
					issue (with an exact URL) are stored in logs for up to 60
					days on Pantheon, which hosts FreedomLab. More information
					on Pantheon’s logs can be found{' '}
					<a
						href='https://docs.pantheon.io/guides/logs-pantheon'
						target='_blank'
						rel='noreferrer'
					>
						on their website
					</a>
					.
				</p>
			</div>
			<div className='policy__text--block'>
				<h3 className='policy__subheader'>Cookies</h3>
				<p className='policy__text'>
					A cookie is a text-only piece of information that a website
					transfers to your computer’s hard disk so that the website
					can remember who you are. A cookie will normally contain the
					name of the internet domain from which the cookie has come,
					the “lifetime” of the cookie, and a value, usually a
					randomly generated unique number. If you wish to restrict or
					block cookies you can set your internet browser to do so –
					click{' '}
					<a
						href='https://www.aboutcookies.org/'
						target='_blank'
						rel='noreferrer'
					>
						here
					</a>{' '}
					for more information.
					<br />
					<br />
					On FreedomLab, the “pll-language” cookie is set for all
					visitors – its max-age is set to “session”, which means that
					it should be purged when all tabs from the website are
					closed. Following registration on FreedomLab, other cookies
					will be engaged. Their max-age is also set to “session”, so
					they should be purged when all tabs from the website are
					closed.
					<br />
					<br />
					If connected to a Google account whilst visiting the site,
					Google may collect some cookies related to any resources you
					use that are linked to Google services (e.g. videos in our
					training centre are hosted through YouTube). An explanation
					of how Google uses cookies can be found{' '}
					<a
						href='https://policies.google.com/technologies/cookies?hl=en-US'
						target='_blank'
						rel='noreferrer'
					>
						on their webpage
					</a>
					.
				</p>
			</div>
			<div className='policy__divider' />
			<h3 className='policy__subheader'>Your Data Rights</h3>
			<p className='policy__text'>
				We take appropriate security measures, such as regular security
				audits and the use of HTTPS, to ensure that we keep your
				connection secure, accurate and up to date, and that we only
				keep your data for as long as is reasonable and necessary.
				However, the transmission of information over the internet is
				never completely secure, so while we do our best to protect
				personal information, we cannot guarantee the security of your
				information at all times. <br />
				<br />
				You can access and remove any personal information that we hold
				about you. If you wish to exercise these rights, or discuss our
				privacy policy further, please{' '}
				<a
					href='https://freedomlab.io/contact-us/'
					target='_blank'
					rel='noreferrer'
				>
					contact us
				</a>
				.
			</p>
		</section>
	);
};

export default PrivacyPolicy;
