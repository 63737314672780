import { IQuote } from '../types/types';

export const quotes: IQuote[] = [
	{
		quote: 'firstQuote',
		case: 'firstSource',
	},
	{
		quote: 'secondQuote',
		case: 'secondSource',
	},
	{
		quote: 'thirdQuote',
		case: 'thirdSource',
	},
	{
		quote: 'fourthQuote',
		case: 'fourthSource',
	},
	{
		quote: 'fifthQuote',
		case: 'fifthSource',
	},
	{
		quote: 'sixthQuote',
		case: 'sixthSource',
	},
];
