import './index.scss';

import React, { FC } from 'react';

import FactorsList from '../../components/FactorsList/FactorsList';
import { useTranslation } from 'react-i18next';

interface IFactors {
	handleModal: () => void;
}

const Factors: FC<IFactors> = ({ handleModal }) => {
	const { t } = useTranslation();

	return (
		<section className='factors' id='factors'>
			<div className='factors__wrapper'>
				<h2 className='factors__header'>
					<span>{t('factors.headerBlack')}</span>
					{t('factors.headerGreen')}
				</h2>
				<p className='factors__subheader'>{t('factors.subheader')}</p>
				<div className='factors__mobile'>
					<FactorsList />
				</div>
				<button className='factors__button' onClick={handleModal}>
					<span>{t('menu.contactUs')}</span>
				</button>
			</div>
			<div className='factors__desktop'>
				<FactorsList />
			</div>
		</section>
	);
};

export default Factors;
