import './index.scss';

import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

const CompensationList: FC = () => {
	const { t } = useTranslation();

	return (
		<ul className='compensationList__list'>
			<li className='compensationList__list--item'>
				<span>{t('compensation.first')}</span>
			</li>
			<li className='compensationList__list--item'>
				<span>{t('compensation.second')}</span>
			</li>
			<li className='compensationList__list--item'>
				<span>{t('compensation.third')}</span>
			</li>
			<li className='compensationList__list--item'>
				<span>{t('compensation.fourth')}</span>
			</li>
			<li className='compensationList__list--item'>
				<span>{t('compensation.fifth')}</span>
			</li>
			<li className='compensationList__list--item'>
				<span>{t('compensation.sixth')}</span>
			</li>
		</ul>
	);
};

export default CompensationList;
