import './index.scss';

import React, { FC } from 'react';

interface QuotesControlsProps {
	handleSlide: (direction: number) => void;
	amount: number;
	slideNumber: number;
}

const QuotesControls: FC<QuotesControlsProps> = ({
	handleSlide,
	amount,
	slideNumber,
}) => {
	return (
		<div className='quotesControls'>
			<div className='quotesControls__bar'>
				<div
					style={{
						width: `calc(100%/${amount})`,
						left: `calc((100%/${amount})*${slideNumber})`,
					}}
				/>
			</div>
			<div className='quotesControls__buttons'>
				<button onClick={() => handleSlide(-1)} />
				<button onClick={() => handleSlide(1)} />
			</div>
		</div>
	);
};

export default QuotesControls;
