import './index.scss';

import React, { FC, ReactNode } from 'react';

interface IBackdrop {
	children: ReactNode;
	handleModal: () => void;
	modalState: boolean;
}

const Backdrop: FC<IBackdrop> = ({ children, handleModal, modalState }) => {
	return (
		<div
			onClick={handleModal}
			className={`backdrop backdrop__${
				modalState ? 'active' : 'disabled'
			}`}
		>
			{children}
		</div>
	);
};

export default Backdrop;
