import './index.scss';

import React, { FC } from 'react';

import BarriersList from '../../components/BarriersList/BarriersList';
import { useTranslation } from 'react-i18next';

interface IBarriers {
	handleModal: () => void;
}

const Barriers: FC<IBarriers> = ({ handleModal }) => {
	const { t } = useTranslation();

	return (
		<section className='barriers' id='barriers'>
			<h2 className='barriers__header'>
				<span>{t('barriers.headerBlack')}</span>
				{t('barriers.headerGreen')}
			</h2>
			<BarriersList />
			<button className='barriers__button' onClick={handleModal}>
				<span>{t('buttons.experience')}</span>
			</button>
		</section>
	);
};

export default Barriers;
