import './index.scss';

import React, { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import menu_open from '../../assets/icons/icon_cancel_white.svg';

const PrivacyModal: FC = () => {
	const [state, setState] = useState(true);

	const onContinue = () => {
		setState(!state);
		localStorage.setItem('selev', JSON.stringify(!state));
	};

	useEffect(() => {
		const storedState = localStorage.getItem('selev');
		if (storedState !== null) {
			setState(JSON.parse(storedState));
		}
	}, []);

	return (
		<div
			className={`privacyModal privacyModal__${
				state === true ? 'active' : 'disabled'
			}`}
		>
			<p className='privacyModal__text'>
				<span>This website does not track cookies. </span>
				We are dedicated to protecting your privacy. This site does not
				track your IP address or add cookies to your browser. Read our
				privacy policy for more information.
			</p>
			<div>
				<span className='privacyModal__link--bold' onClick={onContinue}>
					Continue
				</span>
				{/* <Link className='privacyModal__link' to='privacy_policy'>
					Privacy Policy
				</Link> */}
				<Link className='privacyModal__link' to='/'>
					Privacy Policy
				</Link>
			</div>
			<img src={menu_open} alt='close' onClick={() => setState(!state)} />
		</div>
	);
};

export default PrivacyModal;
