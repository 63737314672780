import './index.scss';

import React, { FC } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import CloseButton from '../CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';

interface IExpertiseModal {
	handleModal: () => void;
	handleFeedback: () => void;
	modalState: boolean;
}

const ExpertiseModal: FC<IExpertiseModal> = ({
	handleModal,
	handleFeedback,
	modalState,
}) => {
	const { t } = useTranslation();

	return (
		<Backdrop handleModal={handleModal} modalState={modalState}>
			<div
				className={`expertiseModal expertiseModal__${
					modalState ? 'active' : 'disabled'
				}`}
				onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
					e.stopPropagation()
				}
			>
				<CloseButton handleClose={handleModal} />
				<h2 className='expertiseModal__header'>
					{t('expertise.header')}
				</h2>
				<h3 className='expertiseModal__subheader'>
					{t('expertise.types')}
				</h3>
				<ul className='expertiseModal__list'>
					<li>
						<span>{t('expertise.firsttype')}</span>
					</li>
					<li>
						<span>{t('expertise.secondttype')}</span>
					</li>
					<li>
						<span>{t('expertise.thirdtype')}</span>
					</li>
					<li>
						<span>{t('expertise.fourthtype')}</span>
					</li>
				</ul>
				<div className='expertiseModal__divider' />
				<h3 className='expertiseModal__subheader'>
					{t('expertise.presentation')}
				</h3>
				<ul className='expertiseModal__list'>
					<li>
						<span>{t('expertise.firstpoint')}</span>
					</li>
					<li>
						<span>{t('expertise.secondtpoint')}</span>
					</li>
					<li>
						<span>{t('expertise.thirdpoint')}</span>
					</li>
				</ul>
				<button
					className='expertiseModal__button'
					onClick={handleFeedback}
				>
					<span>{t('buttons.contact')}</span>
				</button>
			</div>
		</Backdrop>
	);
};

export default ExpertiseModal;
