import './index.scss';

import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

const BarriersList: FC = () => {
	const { t } = useTranslation();

	return (
		<ul className='barriersList__list'>
			<li className='barriersList__list--item'>
				<p>{t('barriers.first')}</p>
			</li>
			<li className='barriersList__list--item'>
				<p>{t('barriers.second')}</p>
			</li>
			<li className='barriersList__list--item'>
				<p>{t('barriers.third')}</p>
			</li>
			<li className='barriersList__list--item'>
				<p>{t('barriers.fourth')}</p>
			</li>
		</ul>
	);
};

export default BarriersList;
