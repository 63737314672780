import './index.scss';

import React, { FC } from 'react';

import menu_close from '../../assets/icons/icon_close.svg';

interface CloseButtonProps {
	handleClose: () => void;
}

const CloseButton: FC<CloseButtonProps> = ({ handleClose }) => {
	return (
		<div className='closeButton' onClick={handleClose}>
			<img src={menu_close} alt='close' />
		</div>
	);
};

export default CloseButton;
