import './index.scss';

import React, { FC } from 'react';

import CompensationList from '../../components/CompensationList/CompensationList';
import { useTranslation } from 'react-i18next';

interface ICompensation {
	handleModal: () => void;
}

const Compensation: FC<ICompensation> = ({ handleModal }) => {
	const { t } = useTranslation();

	return (
		<section className='compensation' id='compensation'>
			<div className='compensation__wrapper'>
				<h2 className='compensation__header'>
					{t('compensation.headerWays')}
					<span>{t('compensation.headerNegotiations')}</span>
					{t('compensation.headerOr')}
					<span>{t('compensation.headerCourts')}</span>
				</h2>
				<p className='compensation__subheader'>
					{t('compensation.subheader')}
				</p>
			</div>
			<CompensationList />
			<button className='compensation__button' onClick={handleModal}>
				<span>{t('menu.contactUs')}</span>
			</button>
		</section>
	);
};

export default Compensation;
