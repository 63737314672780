import './index.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

const FactorsList = () => {
	const { t } = useTranslation();

	return (
		<ul className='factorsList'>
			<li className='factorsList__item'>
				<p>{t('factors.first')}</p>
			</li>
			<li className='factorsList__item'>
				<p>{t('factors.second')}</p>
			</li>
			<li className='factorsList__item'>
				<p>{t('factors.third')}</p>
			</li>
			<li className='factorsList__item'>
				<p>{t('factors.fourth')}</p>
			</li>
		</ul>
	);
};

export default FactorsList;
