import './index.scss';

import React, { FC, useState } from 'react';

import { IQuote } from '../../types/types';
import Quote from '../../components/Quote/Quote';
import QuotesControls from '../../components/QuotesControls/QuotesControls';
import { quotes } from '../../data/quotes';
import { useTranslation } from 'react-i18next';

const Quotes: FC = () => {
	const [slideNumber, setSlideNumber] = useState(0);

	const { t } = useTranslation();

	const handleSlide = (direction: number) => {
		if (direction + slideNumber < 0) {
			setSlideNumber(quotes.length - 1);
		} else {
			setSlideNumber((slideNumber + direction) % quotes.length);
		}
	};

	return (
		<section className='quotes' id='quotes'>
			<div className='quotes__header'>
				<h2>
					<span>{t('quotes.headerGreen')}</span>
					{t('quotes.headerBlack')}
				</h2>
				<p>{t('quotes.text')}</p>
			</div>
			<div className='quotes__slider'>
				<ul className='quotes__slider--list'>
					{quotes.map((item: IQuote, index) => (
						<li
							key={index}
							style={{
								transform: `translateX(-${slideNumber * 100}%)`,
							}}
						>
							<Quote quote={item} />
						</li>
					))}
				</ul>
				<QuotesControls
					handleSlide={handleSlide}
					amount={quotes.length}
					slideNumber={slideNumber}
				/>
			</div>
		</section>
	);
};

export default Quotes;
