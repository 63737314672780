import './index.scss';

import React, { FC, useRef } from 'react';

import Form from '../../components/Form/Form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Socials from '../../components/Socials/Socials';
import logo from '../../assets/icons/logo.svg';
import { useForm } from '../../hooks/useForm';
import { useTranslation } from 'react-i18next';

const Footer: FC = () => {
	const captchaRef = useRef(null);

	const { formData, captchaState, error, onFormSubmit, onInputChange, showConfirmation } = useForm(captchaRef);

	const { t } = useTranslation();

	return (
		<footer className='footer'>
			<div className='footer__logo--wrapper'>
				<img className='footer__logo' src={logo} alt='SeLeV logo' />
				<div className='footer__socials'>
					<Socials iconSize={24} listSize={120} />
					<span className='footer__socials--text'>© SeLeV Consulting Group Ltd © 1995-2024</span>
				</div>
			</div>
			<div className='footer__form'>
				<p className='footer__text'>{t('footer.header')}</p>
				<Form formData={formData} error={error} onFormSubmit={onFormSubmit} onInputChange={onInputChange} />
				<div className='footer__form--wrapper'>
					<button className={`footer__button`} type='submit' onClick={onFormSubmit}>
						<span> {t('buttons.submit')}</span>
					</button>
					{captchaState === 'active' ? (
						<div className='footer__captcha'>
							<ReCAPTCHA ref={captchaRef} sitekey={`${process.env.REACT_APP_KEY}`} onChange={showConfirmation} />
						</div>
					) : null}
					<p className='footer__agreement'>
						{t('footer.consentBlack')}
						{/* <Link to='privacy_policy'>
							{t('footer.consentGreen')}
						</Link> */}
						<Link to='/'>{t('footer.consentGreen')}</Link>
					</p>
				</div>
				<div className='footer__socials'>
					<Socials iconSize={40} listSize={168} />
					<span className='footer__socials--text'>© SeLeV Consulting Group Ltd © 1995-2024</span>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
