import './index.scss';

import React, { FC, useEffect, useState } from 'react';

import ConsultingModal from '../ConsultingModal/ConsultingModal';
import arrow from '../../assets/icons/arrow_services_default.svg';
import consultation from '../../assets/icons/icon_consultation.svg';
import plan from '../../assets/icons/icon_plan.svg';
import { useTranslation } from 'react-i18next';
import well_being from '../../assets/icons/icon_well-being.svg';

interface IConsulting {
	isModalOpen: (value: string) => boolean;
	toggleModal: (modalName: string) => void;
}

const Consulting: FC<IConsulting> = ({ toggleModal, isModalOpen }) => {
	const { t } = useTranslation();

	const [isRendered, setIsRendered] = useState(false);

	const handleModal = () => {
		toggleModal('consulting');
	};

	const handleFeedback = () => {
		toggleModal('feedback');
		handleModal();
	};

	const modalState = isModalOpen('consulting');

	useEffect(() => {
		if (modalState) setIsRendered(true);
	}, [modalState]);

	return (
		<div className='consulting'>
			<div className='consulting__header'>
				<h3>{t('services.consulting')}</h3>
				<img src={arrow} alt='arrow_right' onClick={handleModal} />
			</div>
			<ul className='consulting__list'>
				<li>
					<img src={consultation} alt='icon_consultation' />
					<span>{t('services.consultingOwners')}</span>
				</li>
				<li>
					<img src={plan} alt='icon_plan' />
					<span>{t('services.consultingPlan')}</span>
				</li>
				<li>
					<img src={well_being} alt='icon_well-being' />
					<span>{t('services.consultingWellBeing')}</span>
				</li>
			</ul>
			{isRendered ? (
				<ConsultingModal
					handleModal={handleModal}
					handleFeedback={handleFeedback}
					modalState={modalState}
				/>
			) : null}
		</div>
	);
};

export default Consulting;
