import './index.scss';

import React, { FC } from 'react';

import { IMenuItem } from '../../types/types';
import { Link as ScrollLink } from 'react-scroll';
import { menu } from '../../constants/menu';
import { useTranslation } from 'react-i18next';

interface IMobileMenuList {
	handleMenu?: () => void;
}

const MobileMenuList: FC<IMobileMenuList> = ({ handleMenu }) => {
	const { t } = useTranslation();

	return (
		<ul className='menu__list'>
			{menu.map((item: IMenuItem, index: number) => (
				<li key={index}>
					<ScrollLink
						onClick={handleMenu}
						to={Object.values(item)[0]}
						smooth={true}
						duration={500}
					>
						{t(`menu.${Object.values(item)[0]}`)}
					</ScrollLink>
				</li>
			))}
		</ul>
	);
};

export default MobileMenuList;
