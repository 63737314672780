import React, { FC, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Barriers from './sections/Barriers/Barriers';
import Compensation from './sections/Compensation/Compensation';
import Factors from './sections/Factors/Factors';
import FeedbackModal from './components/FeedbackModal/FeedbackModal';
import Footer from './sections/Footer/Footer';
import Header from './sections/Header/Header';
import { Helmet } from 'react-helmet';
import Hero from './sections/Hero/Hero';
import PrivacyModal from './components/PrivacyModal/PrivacyModal';
import PrivacyPolicy from './sections/PrivacyPolicy/PrivacyPolicy';
import Quotes from './sections/Quotes/Quotes';
import Services from './sections/Services/Services';
import { keywords } from './constants/keywords';
import { useModal } from './hooks/useModal';

const App: FC = () => {
	const { toggleModal, isModalOpen } = useModal();

	const [isRendered, setIsRendered] = useState(false);

	const handleModal = () => {
		toggleModal('feedback');
	};

	const modalState = isModalOpen('feedback');

	useEffect(() => {
		if (modalState) setIsRendered(true);
	}, [modalState]);

	return (
		<Router>
			<Helmet>
				<meta name='keywords' content={keywords.join(', ')} />
			</Helmet>
			<Header
				handleModal={handleModal}
				toggleModal={toggleModal}
				isModalOpen={isModalOpen}
			/>
			<Routes>
				<Route
					path='/'
					element={
						<>
							<Hero />
							<Services
								toggleModal={toggleModal}
								isModalOpen={isModalOpen}
							/>
							<Factors handleModal={handleModal} />
							<Barriers handleModal={handleModal} />
							<Compensation handleModal={handleModal} />
							<Quotes />
						</>
					}
				/>
				<Route path='privacy_policy' element={<PrivacyPolicy />} />
			</Routes>
			<Footer />
			<PrivacyModal />
			{isRendered ? (
				<FeedbackModal
					modalState={modalState}
					handleModal={handleModal}
				/>
			) : null}
		</Router>
	);
};

export default App;
