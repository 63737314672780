import './index.scss';

import React, { FC } from 'react';

import CloseButton from '../CloseButton/CloseButton';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import MobileMenuList from '../MobileMenuList/MobileMenuList';
import Socials from '../Socials/Socials';
import logo from '../../assets/icons/logo.svg';
import { useTranslation } from 'react-i18next';

interface MobileMenuProps {
	toggleModal: (value: string) => void;
	handleFeedback: () => void;
	handleLanguageChange: (value: string) => void;
	currentLanguage: string;
}

const MobileMenu: FC<MobileMenuProps> = ({
	toggleModal,
	handleFeedback,
	handleLanguageChange,
	currentLanguage,
}) => {
	const { t } = useTranslation();

	const handleMenu = () => {
		toggleModal('menu');
	};

	const handleFeedbackModal = () => {
		handleFeedback();
		handleMenu();
	};

	return (
		<div className='menu'>
			<div className='menu__wrapper'>
				<img className='menu__logo' src={logo} alt='SeLeV logo' />
				<CloseButton handleClose={handleMenu} />
			</div>
			<MobileMenuList handleMenu={handleMenu} />
			<LanguageSwitcher
				currentLanguage={currentLanguage}
				handleLanguageChange={handleLanguageChange}
			/>
			<Socials iconSize={40} listSize={184} />
			<button className='menu__button' onClick={handleFeedbackModal}>
				<span>{t('buttons.contact')}</span>
			</button>
		</div>
	);
};

export default MobileMenu;
