import './index.scss';

import React, { FC } from 'react';

import Backdrop from '../Backdrop/Backdrop';
import CloseButton from '../CloseButton/CloseButton';
import { useTranslation } from 'react-i18next';

interface IConsultingModal {
	handleModal: () => void;
	handleFeedback: () => void;
	modalState: boolean;
}

const ConsultingModal: FC<IConsultingModal> = ({
	handleModal,
	handleFeedback,
	modalState,
}) => {
	const { t } = useTranslation();

	return (
		<Backdrop handleModal={handleModal} modalState={modalState}>
			<div
				className={`consultingModal consultingModal__${
					modalState ? 'active' : 'disabled'
				}`}
				onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
					e.stopPropagation()
				}
			>
				<CloseButton handleClose={handleModal} />
				<h2 className='consultingModal__header'>
					{t('consulting.header')}
				</h2>
				<ul className='consultingModal__list'>
					<li>
						<span>{t('consulting.first')}</span>
					</li>
					<li>
						<span>{t('consulting.second')}</span>
					</li>
					<li>
						<span>{t('consulting.third')}</span>
					</li>
					<li>
						<span>{t('consulting.fourth')}</span>
					</li>
				</ul>
				<button
					className='consultingModal__button'
					onClick={handleFeedback}
				>
					<span>{t('buttons.contact')}</span>
				</button>
			</div>
		</Backdrop>
	);
};

export default ConsultingModal;
