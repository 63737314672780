import './index.scss';

import React, { FC } from 'react';

import { ILanguage } from '../../types/types';
import { languages } from '../../locales/languages';
import { useTranslation } from 'react-i18next';

interface ILanguageSwitcher {
	currentLanguage: string;
	handleLanguageChange: (value: string) => void;
}

const LanguageSwitcher: FC<ILanguageSwitcher> = ({
	currentLanguage,
	handleLanguageChange,
}) => {
	const { t } = useTranslation();

	return (
		<ul className='languageSwitcher'>
			{languages.map((item: ILanguage) => (
				<li
					key={item.name}
					className={
						currentLanguage === item.value ? 'active' : 'disabled'
					}
				>
					<span
						className={
							currentLanguage === item.value
								? 'active'
								: 'disabled'
						}
						onClick={() => handleLanguageChange(item.value)}
					>
						{t(`menu.${item.value}`)}
					</span>
				</li>
			))}
		</ul>
	);
};

export default LanguageSwitcher;
