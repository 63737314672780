import { IFormData } from '../types/types';
import axios from 'axios';
import { useState } from 'react';

interface IUseForm {
	formData: IFormData;
	captchaState: string;
	error: string;
	onFormSubmit: (e: React.FormEvent) => void;
	onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	resetFormData: () => void;
	confirmation: boolean;
	showConfirmation: () => void;
	onConfirmationClose: () => void;
}

export const useForm = (ref?: any): IUseForm => {
	const [captchaState, setCaptchaState] = useState('disabled');
	const [confirmation, setConfirmation] = useState(false);
	const [error, setError] = useState('');
	const [formData, setFormData] = useState<IFormData>({
		name: '',
		email: '',
		message: '',
	});

	const resetFormData = () => {
		setFormData({
			name: '',
			email: '',
			message: '',
		});
	};

	const showConfirmation = async () => {
		try {
			const data = await axios.post('https://formspree.io/f/mayrvwqb', {
				formData,
			});
			if (data.status === 200) {
				setConfirmation(true);
				resetFormData();
				setCaptchaState('disabled');
				if (ref) ref.current.reset();
			}
		} catch (err: any) {
			console.log(err);
		}
		// try {
		// 	const data = await axios.post('https://formspree.io/f/xzbngjer', {
		// 		formData,
		// 	});
		// 	if (data.status === 200) {
		// 		setConfirmation(true);
		// 		resetFormData();
		// 		setCaptchaState('disabled');
		// 		if (ref) ref.current.reset();
		// 	}
		// } catch (err: any) {
		// 	console.log(err);
		// }
	};

	const onConfirmationClose = () => setConfirmation(false);

	const onFormSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (!formData.name) {
			setError('noName');
			return;
		}
		if (!formData.email || !/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
			setError('noEmail');
			return;
		}
		if (!formData.message) {
			setError('noMessage');
			return;
		}
		setCaptchaState('active');
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		error && setError('');
		setFormData({ ...formData, [name]: value });
	};

	return {
		formData,
		captchaState,
		error,
		confirmation,
		onFormSubmit,
		onInputChange,
		resetFormData,
		showConfirmation,
		onConfirmationClose,
	};
};
