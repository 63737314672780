import './index.css';
import './locales/i18n';

import App from './App';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(<App />);
