import './index.scss';

import React, { FC } from 'react';

import { IQuote } from '../../types/types';
import { useTranslation } from 'react-i18next';

interface IQuoteProps {
	quote: IQuote;
}

const Quote: FC<IQuoteProps> = ({ quote }) => {
	const { t } = useTranslation();
	
	return (
		<div className='quote'>
			<p className='quote__text'>{t(`quotes.${quote.quote}`)}</p>
			<span className='quote__case'>{t(`quotes.${quote.case}`)}</span>
		</div>
	);
};

export default Quote;
