export const keywords: string[] = [
	'моральный вред',
	'non-pecuniary damage',
	'моральный ущерб',
	'moral harm',
	'экспертизы',
	'expertise',
	'судебная экспертиза',
	'forensic psychological examination',
	'оценить моральный вред',
	'evaluation of non-pecuniary damage',
	'оценка морального ущерба',
	'моральні збитки',
	'моральні збитки Волочай',
	'моральный вред Волочай',
	'моральна шкода Волочай',
];
