import './index.scss';

import React, { FC } from 'react';

import { IForm } from '../../types/types';
import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';

const Form: FC<IForm> = ({ formData, error, onFormSubmit, onInputChange }) => {
	const { t } = useTranslation();

	return (
		<form className='form' onSubmit={(e) => onFormSubmit(e)}>
			<Input
				type='text'
				id='name'
				minLength={1}
				maxLength={30}
				required={true}
				label={t('footer.name')}
				handleInput={onInputChange}
				formData={formData}
				error={error}
			/>
			<Input
				type='email'
				id='email'
				required={true}
				label={t('footer.email')}
				handleInput={onInputChange}
				formData={formData}
				error={error}
			/>
			<Input
				type='text'
				id='message'
				minLength={2}
				maxLength={500}
				required={true}
				label={t('footer.message')}
				handleInput={onInputChange}
				formData={formData}
				error={error}
			/>
			<span
				className={`form__required ${
					error === 'noMessage' ? 'error' : ''
				}`}
			>
				{t('footer.required')}
			</span>
		</form>
	);
};

export default Form;
