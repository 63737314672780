import './index.scss';

import React, { FC } from 'react';

import facebook from '../../assets/icons/icon_facebook.svg';
import signal from '../../assets/icons/icon_signal.svg';
import whatsapp from '../../assets/icons/icon_whatsapp.svg';

interface ISocials {
	iconSize: number;
	listSize: number;
}

const Socials: FC<ISocials> = ({ iconSize, listSize }) => {
	return (
		<div className='socials' style={{ width: `${listSize}px` }}>
			<ul className='socials__list'>
				<li>
					<a
						style={{ height: `${iconSize}px` }}
						href='https://www.facebook.com/SeLeVCG/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={facebook} alt='facebook' />
					</a>
				</li>
				<li>
					<a
						style={{ height: `${iconSize}px` }}
						href={`https://signal.me/#${process.env.REACT_APP_CONTACT}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={signal} alt='signal' />
					</a>
				</li>
				<li>
					<a
						style={{ height: `${iconSize}px` }}
						href={`https://wa.me/${process.env.REACT_APP_CONTACT}`}
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src={whatsapp} alt='whatsapp' />
					</a>
				</li>
			</ul>
		</div>
	);
};

export default Socials;
