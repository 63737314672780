import './index.scss';

import React, { FC, useEffect, useState } from 'react';

import ExpertiseModal from '../ExpertiseModal/ExpertiseModal';
import arrow from '../../assets/icons/arrow_services_default.svg';
import speech from '../../assets/icons/icon_speech.svg';
import support from '../../assets/icons/icon_support.svg';
import { useTranslation } from 'react-i18next';

interface IExpertise {
	isModalOpen: (value: string) => boolean;
	toggleModal: (modalName: string) => void;
}

const Expertise: FC<IExpertise> = ({ toggleModal, isModalOpen }) => {
	const { t } = useTranslation();

	const [isRendered, setIsRendered] = useState(false);

	const handleModal = () => {
		toggleModal('expertise');
	};

	const handleFeedback = () => {
		toggleModal('feedback');
		handleModal();
	};

	const modalState = isModalOpen('expertise');

	useEffect(() => {
		if (modalState) setIsRendered(true);
	}, [modalState]);

	return (
		<div className='expertise'>
			<div className='expertise__header'>
				<h3>{t('services.expertise')}</h3>
				<img src={arrow} alt='arrow_right' onClick={handleModal} />
			</div>
			<ul className='expertise__list'>
				<li>
					<img src={support} alt='icon_support' />
					<span>{t('services.expertiseAssistance')}</span>
				</li>
				<li>
					<img src={speech} alt='icon_speech' />
					<span>{t('services.expertiseRepresentation')}</span>
				</li>
			</ul>
			{isRendered ? (
				<ExpertiseModal
					handleModal={handleModal}
					handleFeedback={handleFeedback}
					modalState={modalState}
				/>
			) : null}
		</div>
	);
};

export default Expertise;
